.btn-contacto
{
    background-color: #cd6c6c;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    padding: 10px;
    place-self: center;
    text-align: center;
    text-decoration: none;
    width: 250px;
}
.btn-contacto:hover{
    background-color: #dba99e;
    color: white;
    font: bolder;
}
.btn-abogado{
    background-color: #cd6c6c;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    padding: 10px;
    place-self: center;
    text-align: center;
    text-decoration: none;
    width: 100%;
}
@media screen and (max-width: 1000px) {
    .btn-contacto{
        font-size: 0.8rem;
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .btn-contacto{
        font-size: 0.8rem;
        width: 100%;
    }
}