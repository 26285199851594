
.carousel{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.carousel h3{
    font-size: 2rem;
    font-weight: bold;
}
.carousel p{
    font-size: 1.5rem;
}

.blanco h3 {
    color: white
}
.blanco p {
    color: white;
}


img{
    object-fit: scale-down;
}

@media screen and (max-width: 900px) {
    .carousel{
        height: 300px;
        width: 1fr;
    }
    .carousel-caption{
        background-color: rgba(242, 241, 241, 0.41);
        border-radius: 10px;
        margin-bottom: 50px;
    }
    
    .carousel h3{
        font-size: 1.5rem;
        opacity: 1;
    }
    .carousel p{
        font-size: 1rem;
    }
    
    img{
        object-fit: cover;
        height: 300px;
    }

    .blanco h3 {
        color: rgb(35, 35, 35);
    }
    .blanco p {
        color: rgb(35,35,35);

    }
    
}

