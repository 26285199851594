.abogados{
    margin: 50px 200px;
}

.abogados-grid{
    display: grid;
    gap: 15px;
    grid-auto-rows: minmax(auto-fit, 300px);
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center;
}
   
@media screen and (max-width: 1030px) {
    .abogados{
        margin: 25px 50px;
    }
}
    
@media screen and (max-width: 768px) { 
    .abogados-grid{
        grid-auto-rows: 250px;
        grid-template-columns: repeat(auto-fit, 280px);
    }
}

@media screen and (max-width: 400px) {
    .abogados-grid{
        grid-auto-rows: 250px;
        grid-template-columns: repeat(auto-fit, 200px);
    }

}