
.nosotros{
    margin: 50px 200px;
}

.nosotros-title{
    color: rgb(84, 84, 84);
    font-size: 2rem;
    text-align: center;
}
.nosotros-parraph{
    font-size: 1.2rem;
    text-align: center;
    text-justify: inter-word;
}

@media screen and (max-width: 1130px) {
    .nosotros{
        margin: 25px 50px;
    }
}
@media screen and (max-width: 768px) { 
    .nosotros{
        margin: 30px 40px;
    }

}


