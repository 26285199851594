
.esp-info{
    margin: 25px 200px;
}

.esp-title{
    color: rgb(84, 84, 84);
    font-size: 2rem;
    text-align: center;
}
.esp-subtitle{
    color: rgb(84, 84, 84);
    font-size: 1rem;
    text-align: center;
}

.esp-desc{
    color: rgb(84, 84, 84);
    font-size: 1.5em;
    margin-bottom: 100px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
    text-justify: inter-word;
}

.esp-p{
    margin-top: 50px;
    font-size: 1rem;
}

.esp-btn{
    background-color: #cd6c6c;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    margin: 20px;
    padding: 10px;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.esp-btn:hover{
    background-color: #dba99e;
}

.acordion{
    border: 1px solid #e6e6e6;
    margin: 10px;
}

.esp-more-info{
    margin-top: 100px;
    text-align: center;
}


.accordion-button.collapsed::after {
    color: #212529;
  }
.accordion-button:focus {
    box-shadow: none;
}
.accordion-button:not(.collapsed) {
    color: #212529;
    background-color: tomato;
}
.accordion-button:focus {
box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #dba99e;
}



@media screen and (max-width: 800px){
    .esp-info{
        margin: 20px 10px;
    }
    .esp-more-info{
        margin: 20px 50px;
    }
    .esp-btn{
        max-width: 200px;
    }

}

@media screen and (max-width: 400px){
    .esp-title{
        font-size: 1.5rem;
    }
    .esp-desc{
        font-size: 1.3rem;
    }
    .esp-info{
        margin: 5px 6px;
    }
    .esp-more-info{
        margin: 50px 2px;
    }
    .esp-btn{
        max-width: 200px;
    }
}