
.portada-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    height: 500px;
    object-fit: cover;
}


@media screen and (max-width: 1130px) {
    .portada-img{
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .portada-img{
        width: 300px;
        height: 300px;
    }
}