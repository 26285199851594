
.navbar{
  background-color: #640101;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.navbar-brand {
    margin-left: 25px;
}
.navbar-nav{
  margin-left: 25px;
  margin-right: 25px;
}
.navbar-nav .nav-link{
  color: white;
  font-size: 1.2rem;
  place-self: left;
}

.custom-toggler{
  margin-left: 25px;
  margin-right: 25px;
}

@media screen and (max-width: 330px){
  .navbar-brand{
    font-size: 0.9rem;
  }
}