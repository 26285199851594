
.abogado-card{
    background-color: #f6f4f4;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: minmax(auto-fit, 300px) minmax(auto-fit, 300px);
    justify-content: center;
}

.abogado-img{
    border-radius: 10px;
    grid-column: 1 / 2;
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.abogado-body{
    grid-column: 2 / 2;
    display: grid;
    grid-template-rows: repeat(4, 1fr)
}

.abogado-title{
    font: bolder;
    font-size: 1.5rem;
    grid-row: 1 / 2;
    margin-left: 10px;
    text-align: start;
}

.abogado-desc{
    align-self: start;
    color: #5e5f61 !important;
    font-size: 1rem;
    grid-row: 2 / 4;
    margin-left: 10px;
    margin-right: 10px;
}
.abogado-link{
    align-self: end;
    grid-row: 4 / 5;
    justify-self: start;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 10px;

    font-weight: bold;
    color: #5e5f61;
    text-decoration: none;
}
.abogado-link:hover{
    color: #cd6c6c;
    font-weight: bolder;
}

@media screen and (max-width: 768px) {
    .abogado-card{
        grid-template-columns: 1fr 2fr;
    }
    .abogado-title{
        font-size: 1.5rem;
        margin-left: 0px;
        text-align: center;
    }
    .abogado-desc{
        font-size: 1rem;
        text-align: left;
    }
    .abogado-link{
        justify-self: start;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 10px;
    }
    .abogado-img{
        height: 95%;
    }
}

@media screen and (max-width: 400px) {
    .abogado-grid{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 2fr;
    }
    .abogado-card{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, 1fr);
    }
    .abogado-img{
        border-radius: 360px;
        grid-column: 1 / 3;
        width: 70px;
        height: 70px;
        place-self: center;
    }
    .abogado-body{
        grid-row: 2 / 3;
        grid-column: 1/ 3;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 40px);
    }
    .abogado-title{
        font-size: 1.2rem;
        grid-row: 1 / 2;
        margin-left: 0px;
        text-align: center;
    }
    .abogado-desc{
        font-size: 0.8rem;
        grid-row: 2 / 2;
        text-align: center;
    }
    .abogado-link{
        grid-column: 1 / 2;
        justify-self: center;
        font-size: 0.8rem;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 10px;
    }
}