.categorias{
    margin: 10px 100px;
}

.especialidades{
    margin: 50px 200px;
}

.especialidades-title{
    color: rgb(84, 84, 84);
    font-size: 2rem;
    text-align: center;
}

.especialidad-grid{
    display: grid;
    gap: 15px;
    grid-auto-rows: minmax(auto-fit, 300px);
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center;
}

@media screen and (max-width: 1130px) {
    .categorias{
        margin: 0px 0px
    }
    .especialidades{
        margin: 25px 50px;
    }
}
@media screen and (max-width: 768px) { 
    .especialidades{
        margin: 25px 5px;
    }
    .especialidad-grid{
        grid-template-columns: repeat(auto-fit, 280px);
        grid-auto-rows: 250px;
    }
}

@media screen and (max-width: 400px) { 
    .especialidad-grid{
        grid-template-columns: repeat(auto-fit, 200px);
        grid-auto-rows: 250px;
    }
}