.footer{
    background-color: #640101;
    padding: 20px;
    margin-top: 200px;
}

.footer-grid{
    align-self: center;
    margin: 20px minmax(80px, 200px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 20px;
}
.footer-logo{
    grid-column: 1/3;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    align-self: center;
}

.footer-column{
    align-self: start;
    justify-self: center;
    display: grid;
    grid-row: 2/3;
    margin: 10px 100px;
    grid-template-columns: 300px;
    grid-template-rows: 1fr;
    gap: 10px
}


.footer-column-title{
    font-size: 1.8rem;
    color: #ffffff;
    place-self: center;
    margin-bottom: 50px;
}

.footer-link{
    color: #ffffff;
    font-size: 1.2rem;
    text-decoration: none;
    text-align: left;
    margin: 10px 10px;
}
.footer-link:hover{
    color: #cd6c6c;
    text-decoration: underline;
}

.link-social{
    color: #ffffff;
    font-size: 1.2rem;
    text-decoration: none;
    text-align: left;
    margin: 10px 10px;
}
.link-social:hover{
    color: #cd6c6c;
    text-decoration: underline;
}

.footer-parraph{
    color: #ffffff;
    font-size: 1.2rem;
    text-align: left;
    margin: 10px 10px;
}

.footer-a{
    color: #ffffff;
    font-size: 1.2rem;
    text-align: left;
    margin: 10px 10px;
    text-decoration: none;
}
.footer-a:hover{
    color: #cd6c6c;
    text-decoration: underline;
    font:bolder;
}

@media screen and (max-width: 1100px){
    .footer-grid{
        margin: 20px 0px;
    }
    .footer-column{
        margin: 10px 0px;
    }
    .footer-logo{
        object-fit: contain;
    }
}

@media screen and (max-width: 700px){
    .footer-logo{
        width: 250px;
    }
    .footer-grid{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px;
    }
    .footer-column{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 10px;
    }
    .footer-column-title{
        font-size: 1.5rem;
    }
    .footer-link{
        font-size: 1rem;
    }
    .link-social{
        font-size: 1rem;
    }
    .footer-parraph{
        font-size: 1rem;
    }
    .footer-a{
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px){
    .footer-grid{
        grid-template-columns: 1fr;
        grid-template-rows: 150px;
        grid-auto-rows: 1fr;
        grid-gap: 20px;
    }
    .footer-logo{
        grid-column: 1/2;
        width: 200px;
        height: 100px;
    }
    .footer-column{
        grid-row: auto;
    }
}
