
.cards-grid{
    background-color: #f6f4f4;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: grid;
    grid-template-rows: repeat(6, minmax(33px, 58.3px));
    justify-content: center;
}
.card-img{
    border-radius: 10px;
    grid-area: 1 / 1 / 3 / 2;
    height: 100%;
    object-fit: contain ;
    width: 100%;
}
.card-img img{
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.card-title{
    font: bolder;
    font-size: 1.5rem;
    grid-row: 3 / 4;
    margin-left: 10px;
    text-align: start;
}

.card-desc{
    grid-row: 4 / 6;
    align-self: start;
    color: #5e5f61 !important;
    font-size: 1.1rem;
    padding: 2px 10px;
    max-height: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}

.card-link{
    align-self: end;
    grid-row: 6 / 7;
    justify-self: center !important;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 10px;

    font-weight: bold;
    color: #5e5f61;
    text-decoration: none;
}
.card-link:hover{
    color: #cd6c6c;
    font-weight: bolder;

}
@media screen and (max-width: 768px) {
    .card-grid{
        grid-template-rows: repeat(6, minmax(33px, 50px));
    }
    .card-img{
        grid-row: 1 / 2;
    }
    .card-title{
        font-size: 1.2rem;
        grid-row: 2 / 3;
        margin-left: 0px;
        text-align: center;
    }
    .card-desc{
        grid-row: 3 / 6;
        text-align: left;
        font-size: 1rem;
    }
    .card-link{
        justify-self: start;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 10px;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 400px) {
    .card-img{
        grid-row: 1 / 2;
    }
    .card-title{
        font-size: 1rem;
        grid-row: 2 / 3;
        margin-left: 0px;
        text-align: center;
    }
    .card-desc{
        font-size: 0.8rem;
        grid-row: 3 / 6;
        text-align: left;
    }
    .card-link{
        justify-self: start;
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 10px;
    }
}