
.abogado{
    margin-bottom: 200px;
    margin: 25px 200px;
    max-width: 2000px;
}

.ab-title{
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
}

.ab-grid{
    display: grid;
    gap: 15px;
    grid-auto-rows: minmax(auto-fit, 300px);
    grid-template-columns:  300px 1fr;
    place-content: center;
    margin-bottom: 100px;
}

.ab-img{
    align-self: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 100%;
    object-fit: cover;
    grid-column: 1 / 2;
    width: 100%;
}
.ab-info{
    align-self: center;
    grid-column: 2 / 3;
    padding: 15px;
    font-size: 1rem;
}

.ab-buttons{
    grid-template-columns: 1fr 2fr;
    display: grid;
    gap: 10px;
}

.ab-button{
    background-color: #a36051;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    padding: 10px;
    text-align: center;
    width: 100%;
}

.ab-button:hover{
    background-color: #dba99e;
}

.ab-slogan{
    font-weight: bold;
    text-align: center;
    color: #4b4a4a;
    margin-bottom: 100px;
    margin-left: 300px;
    margin-right: 300px;
}
.slogan-title{
    font-size: 1.2rem;
    font-weight: bold;
}
@media screen and (max-width: 1200px) {
    .abogado{
        margin: 10px 10px;
    }
    .ab-img{
        place-self: start;
        height:300px;
        width: 300px;
    }
    .ab-slogan{
        margin-left: 100px;
        margin-right: 100px;
    }
}
    
@media screen and ( max-width: 768px ) {
    .ab-grid{
        grid-template-rows: auto-fit 1fr;
        grid-template-columns: 1fr;
        gap: 0px;
    }
    .ab-img{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        border-radius: 360px;
        height: 280px;
        width: 280px;
        place-self: center;
    }
    .ab-info{
        grid-column: 1 / 3;
        grid-row: 2/ 3;
    }
    .ab-slogan{
        margin-left: 50px;
        margin-right: 50px;
    }
}
@media screen and ( max-width: 400px ) {
    .ab-grid{
        grid-template-rows: repeat(auto-fit, 200px);
        grid-auto-columns: 200px;
        gap: 0px;
    }
    .ab-img{
        border-radius: 360px;
        height: 200px;
        width: 200px;
        place-self: center;
    }
    .ab-info{
        margin: 1px 10px;
        font-size: 0.9rem;
        padding: 15px;
    }
    .ab-slogan{
        margin-left: 10px;
        margin-right: 10px;
    }
    .slogan-title{
        font-size: 1rem;
    }
}