body{
  padding-top: 55px;
}

.principal {
    margin-left: 25px;
    margin-right: 25px;
  }

.carousel-inner  {
    height: 500px;
  }

.carousel-item {
    height: 300px;
}

.div-abogado{
  margin: 50px 200px;
}
.abogados-title{
    color: rgb(84, 84, 84);
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
}
@media screen and (max-width: 1030px) {
  .div-abogado{
      margin: 25px 0px;
  }
}
*{ 
  font-family: system-ui,-apple-system,"Segoe UI","Roboto","Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}

