.contacto {
    margin: 25px 100px;
}

.contacto-mapa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: flex-end;
}

.contacto-mapa iframe {
    border: 1px solid #cd6c6c;
    border-radius: 5px;
    place-self: center;
    outline: none;
    height: 400px;
    margin-Bottom: 50px;
    width: 400px;
}

.contacto-mapa iframe:active{
    border: 2px solid red;
}

.contacto-abogados {
    margin: 50px;
}

.contacto-title {
    color: rgb(84, 84, 84);
    font-size: 2em;
    text-align: center;
}

.contacto-info-title {
    color: rgb(84, 84, 84);
    font-size: 1.5em;
    text-align: center;
}

.contacto-info-subtitle {
    color: rgb(82, 82, 82);
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
}

.contacto-info-parraph {
    font-size: 1.2rem;
    text-align: center;
}

.contacto-grid {
    display: grid;
    gap: 50px;
    grid-auto-columns: minmax(auto-fit, 300px);
    grid-template-rows: 1fr 1fr;
    margin: 50px minmax(100px, 200px);
}

.contacto-item-grid {
    display: grid;
    gap: 10px;
    grid-auto-columns: minmax(200px, auto);
    grid-template-rows: 1fr 1fr 1fr;
}

.contacto-item-title {
    font-size: 2rem;
    text-align: center;
    place-self: center;
}

.contacto-item-parraph {
    font-size: 1.2rem;
    text-align: center;
}

.contacto-item-tel {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    place-self: center;
}

@media screen and (max-width: 1000px) {
    .contacto {
        margin: 25px 50px;
    }

    .contacto-title {
        font-size: 1.8rem;
    }

    .contacto-info-title {
        font-size: 1.2em;
    }

    .contacto-info-subtitle {
        font-size: 1rem;
    }

    .contacto-info-parraph {
        font-size: 1rem;
    }

    .contacto-grid {
        margin: 25px 0px;
        gap: 10px;
    }

    .contacto-item-title {
        font-size: 1.5rem;
    }

    .contacto-item-parraph {
        font-size: 1rem;
    }
    .contacto-item-tel {
        width: 100%;
    }

    .contacto-mapa iframe {
        margin-Bottom: 5px;
    }
    
}

@media screen and (max-width: 550px) {
    .contacto {
        margin: 5px 6px;
    }

    .contacto-title {
        font-size: 2em;
    }

    .contacto-info-title {
        font-size: 1.2rem;
    }

    .contacto-info-subtitle {
        font-size: 1rem;
    }

    .contacto-info-parraph {
        font-size: 1rem;
    }

    .contacto-grid {
        margin: 25px 5px;
    }

    .contacto-item-grid {
        gap: 1px;
        grid-auto-columns: auto;
    }

    .contacto-item-title {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
    }

    .contacto-item-parraph {
        font-size: 0.8rem;
        text-align: center;
    }
}